import { ElementContainerSettings, GroupContainerSettings } from 'modules/settingsContainer';
import { FlexContainer } from 'styles/FlexContainer';
import { Groups } from 'modules/settingsContainer/ColorPicker/styles';
import {
  colorGap,
  colorSize,
  maxColorInGroup,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/constants';
import { GroupIdInterface, GroupItemInterface, PaletteItemInterface } from 'store/reducers/palettes/types';
import {
  GroupNameTextSpan,
  GroupNameWrapper,
  GroupsWrapper,
  StyledTextInput,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/styles';
import { AddFolder, CopyIcon, DeleteIcon, GroupCopy, LinkIcon } from 'assets/icons/withContainer';
import React, { ChangeEvent, KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ActiveColorInterface,
  AddColorType,
  AddGroupType,
  ChangeColorsType,
  ChangeColorType,
  ChangeGroupNameType,
  CopyGroupType,
  DeleteColorType,
  DeleteGroupType,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/types';
import {
  changePaletteColor,
  defaultActiveColor,
  exportFlowers,
  exportJsonFile,
  findCopyIndex,
  findLastCopyIndex,
  isActiveColor,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/constants';
import { EditableItemInterface, OnValueChange } from 'types/global';
import { HexAndHlsColorEditor } from 'modules/ui/colors/HexAndHlsColorEditor';
import { ClickAwayListener } from '@mui/material';
import { useToggle } from 'utils/hooks/toggle';
import { HlsColorEditorProps } from 'modules/ui/colors/HlsColorEditor';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ExportIcon, ImportIcon } from 'assets/icons/navigations';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import snackbar from 'services/Snackbar';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { ExportDataInProject } from 'components/shared/ExportArrayDataInProject';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';
import { updatePaletteById } from 'store/reducers/palettes';
import { isTheJSONvalid, isValidGroupsColorJSON } from 'utils/json';
import { ColorTransparent } from 'components/shared/ColorTransparent';
import { ProjectIdParam } from 'app/providers/router/types';

interface EditableGroupNameProps extends OnValueChange<string>, GroupIdInterface {}

export const EditableGroupName = ({ groupId, value, onChange }: EditableGroupNameProps) => {
  const [editGroup, setEditGroup] = useState<EditableItemInterface<string> | null>(null);

  const onSave = () => {
    onChange(editGroup?.value || value);
    setEditGroup(null);
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter' && !!editGroup) {
      onSave();
    }
  };

  return (
    <GroupNameWrapper>
      {editGroup?.key === groupId ? (
        <ClickAwayListener onClickAway={onSave}>
          <StyledTextInput
            autoFocus
            name={`edit_${groupId}`}
            width="100%"
            value={editGroup.value}
            size={editGroup.value.length + 5}
            onChange={(event) => setEditGroup({ value: event.target.value, key: groupId })}
            onKeyDown={onKeyDown}
          />
        </ClickAwayListener>
      ) : (
        <GroupNameTextSpan onDoubleClick={() => setEditGroup({ value, key: groupId })}>{value}</GroupNameTextSpan>
      )}
    </GroupNameWrapper>
  );
};

interface PaletteColorSettingsProps {
  palette: PaletteItemInterface;
  onAddGroup: AddGroupType;
  onAddColor: AddColorType;
  onDeleteColor: DeleteColorType;
  onDeleteGroup: DeleteGroupType;
  onColorChange: ChangeColorType;
  onCopyGroup: CopyGroupType;
  onColorsChange: ChangeColorsType;
  onGroupNameChange: ChangeGroupNameType;
}

export const PaletteColorSettings = ({
  palette,
  onAddColor,
  onAddGroup,
  onDeleteColor,
  onDeleteGroup,
  onCopyGroup,
  onColorChange,
  onColorsChange,
  onGroupNameChange,
}: PaletteColorSettingsProps) => {
  const { projectId } = useParams<ProjectIdParam>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [activeColor, setActiveColor] = useState<ActiveColorInterface>(defaultActiveColor);
  const [synchronizationGroupMode, toggleSynchronizationGroupMode] = useToggle(false);

  const dispatch = useAppDispatch();

  const isSelectedColor = useMemo(() => !!activeColor.activeColorIds, [activeColor.activeColorIds]);

  const activeColorData = useMemo(() => {
    if (activeColor.activeColorIds && activeColor.activeColorIds.colorId) {
      const { groupId, colorId } = activeColor.activeColorIds;
      const colorData = palette.groups.find(({ id }) => id === groupId)?.colors?.find(({ id }) => id === colorId);

      if (colorData) {
        const { h, s, l, opacity, hex } = colorData;

        return { h, s, l, opacity, hex };
      }
    }

    return null;
  }, [activeColor, palette.groups]);

  const isLastGroup = useMemo(() => palette.groups.length === 1, [palette.groups.length]);

  const isLastColor = useMemo(
    () => isLastGroup && palette.groups[0].colors.length === 1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLastGroup, palette.groups[0].colors.length],
  );

  const activeGroupId = useMemo(
    () => activeColor.activeColorIds?.groupId || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeColor.activeColorIds?.groupId],
  );

  const onLocalDeleteColor = () => {
    if (isSelectedColor && activeColor.activeColorIds && activeColor.colorIndex !== null) {
      const { colorId, groupId } = activeColor.activeColorIds;
      onDeleteColor({ colorId: colorId || '', groupId: activeGroupId });

      const colors = palette.groups.find((group) => group.id === groupId)?.colors,
        newColors = [...(colors || [])];

      newColors.splice(activeColor.colorIndex, 1);

      const colorIndex = activeColor.colorIndex === 0 ? activeColor.colorIndex + 1 : activeColor.colorIndex - 1,
        newActiveColorId = colors?.[colorIndex]?.id,
        newActiveColorIndex = newColors.findIndex(({ id }) => id === newActiveColorId);

      if (newActiveColorId && newActiveColorIndex !== -1) {
        setActiveColor({
          activeColorIds: { groupId, colorId: newActiveColorId },
          colorIndex: newActiveColorIndex,
        });
      }
    }
  };

  const onLocalDeleteGroup = () => {
    if (isSelectedColor && activeColor.activeColorIds && activeColor.colorIndex !== null) {
      onDeleteGroup({ groupId: activeColor.activeColorIds.groupId });

      setActiveColor(defaultActiveColor);
    }
  };

  const onLocalColorChange: HlsColorEditorProps['onChange'] = (color) => {
    if (isSelectedColor && activeColor.activeColorIds && activeColorData) {
      const { groupId, colorId } = activeColor.activeColorIds;

      onColorChange({ groupId, colorId, color });
    }
  };

  const onLocalColorsChange: HlsColorEditorProps['onChange'] = (color, type) => {
    if (isSelectedColor && activeColor.activeColorIds && activeColorData) {
      const { groupId } = activeColor.activeColorIds;

      onColorsChange({ groupId, color: { [type]: color[type] } });
    }
  };

  const onHexAndHlsColor = debounce((color, type) => {
    synchronizationGroupMode ? onLocalColorsChange(color, type) : onLocalColorChange(color, type);
  }, 300);

  const disableDeleteButton = synchronizationGroupMode ? isLastGroup : !isSelectedColor || isLastColor;

  useEffect(() => {
    setActiveColor(defaultActiveColor);
  }, [palette.id]);

  const handleCopyGroup = () => {
    const activeGroup = palette.groups.find(({ id }) => id === activeGroupId);

    if (activeGroup) {
      const nameArr = activeGroup.name.split(' ');
      const defaultName = nameArr[nameArr.length - 1].includes('(')
        ? nameArr.slice(0, nameArr.length - 1).join(' ')
        : activeGroup.name;

      const nextIndex = findCopyIndex(
        palette.groups.map(({ name }) => name),
        defaultName,
      );

      const name = `${defaultName} (${nextIndex})`;
      const colors = activeGroup.colors.map((color) => ({ ...color, id: uuidv4() }));

      const lastCopyIndex = findLastCopyIndex(palette.groups, defaultName);

      onCopyGroup({ group: { colors, name }, index: lastCopyIndex + 1 });
    }
  };

  const handleCopyColor = async () => {
    const result = await onAddColor({
      color: activeColorData,
      groupId: activeGroupId,
    });

    if (result) {
      const { groupId, colorId } = result;
      const colorGroup = palette.groups.find(({ id }) => id === groupId);

      if (colorGroup) {
        const colorIndex = colorGroup.colors.length - 1;

        setActiveColor({
          activeColorIds: { groupId, colorId },
          colorIndex: colorIndex,
        });
      }
    }
  };

  const onCloseAddFlowAccessModal = () => dispatch(closeModalAction(exportFlowers));

  const onChangeExportDataInProject = useCallback(
    (value: string[]) => {
      const exportGroupColors = palette.groups.filter(({ id }) => value.includes(id));
      const data = JSON.stringify(exportGroupColors);

      exportJsonFile(data, 'colors');
    },
    [palette.groups],
  );

  const onClickExport = useCallback(() => {
    projectId &&
      dispatch(
        openModalTypedAction({
          Component: ExportDataInProject,
          componentProps: {
            onClose: () => onCloseAddFlowAccessModal(),
            onCancel: () => onCloseAddFlowAccessModal(),
            title: 'Выберите группы цветов для экспорта',
            errorMessage: 'Выберите хотя бы один цвет для экспорта',
            onChange: onChangeExportDataInProject,
            value: palette.groups.map(({ name, id }) => {
              return { name, id, isChecked: false };
            }),
          },
          modalSettings: {
            headerText: 'Экспорт цветов',
            width: '280px',
          },
          name: exportFlowers,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCloseAddFlowAccessModal, palette.groups, projectId]);

  const handleButtonClick = () => fileInputRef.current?.click();

  const handleFileUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, replaceColors: boolean) => {
      const file = event.target.files?.[0];

      if (file && projectId) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const target = e.target as FileReader;
          const result = target.result;

          if (
            typeof result === 'string' &&
            isTheJSONvalid<GroupItemInterface[]>({ json: result, validateFunc: isValidGroupsColorJSON })
          ) {
            const groups: GroupItemInterface[] = JSON.parse(result);

            const updatedGroups = changePaletteColor({ replaceColors, groups, oldGroups: palette.groups });

            dispatch(updatePaletteById({ ...palette, groups: updatedGroups }));
          } else {
            snackbar.show('Невалидный файл', 'error');
          }
        };

        reader.readAsText(file);

        event.target.value = '';
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [palette, projectId],
  );

  const onClickImport = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        openConfirmationModalAction({
          titleText: 'Импорт цветов',
          onConfirm: () => handleFileUpload(event, true),
          onCancel: () => handleFileUpload(event, false),
          confirmationButtonText: 'Заменить',
          cancelButtonText: 'Нет',
          subTitleText: 'Заменить цвета при совпадении имен?',
          width: '320px',
        }),
      );
    },
    [dispatch, handleFileUpload],
  );

  return (
    <GroupContainerSettings>
      <ElementContainerSettings>
        <GroupsWrapper>
          {palette.groups.map(({ name, id: groupId, colors }) => (
            <FlexContainer key={groupId} width="100%" flexDirection="column">
              {palette.showGroupNames && (
                <EditableGroupName groupId={groupId} value={name} onChange={(name) => onGroupNameChange({ name, groupId })} />
              )}
              <Groups maxColorInGroup={maxColorInGroup} colorGap={colorGap} colorSize={colorSize}>
                {colors.map(({ id: colorId, hex }, colorIndex) => {
                  const newActiveColorIds = { colorId, groupId },
                    onColorClick = () =>
                      setActiveColor(({ activeColorIds }) =>
                        isActiveColor(newActiveColorIds, activeColorIds)
                          ? defaultActiveColor
                          : { activeColorIds: newActiveColorIds, colorIndex },
                      );

                  return (
                    <ColorTransparent
                      active={isActiveColor(newActiveColorIds, activeColor?.activeColorIds)}
                      onClick={onColorClick}
                      key={colorId}
                      color={hex}
                      colorSize={colorSize}
                    />
                  );
                })}
              </Groups>
            </FlexContainer>
          ))}
        </GroupsWrapper>
      </ElementContainerSettings>
      <ElementContainerSettings>
        <FlexContainer width="100%" justifyContent="space-between" alignItems="center" marginBottom="10px">
          <FlexContainer gap="3px" alignItems="center">
            <TooltipIconButton
              title="Создать группу"
              leftIcon={<AddFolder />}
              onClick={() => onAddGroup({ color: activeColorData })}
            />
            <TooltipIconButton
              title="Дублировать группу"
              leftIcon={<GroupCopy />}
              onClick={handleCopyGroup}
              disabled={!activeGroupId}
            />
            <TooltipIconButton
              title="Дублировать"
              disabled={!isSelectedColor}
              leftIcon={<CopyIcon />}
              onClick={handleCopyColor}
            />
            <TooltipIconButton title="Импортировать" leftIcon={<ImportIcon />} onClick={handleButtonClick} />
            <input type="file" accept=".json" onChange={onClickImport} ref={fileInputRef} style={{ display: 'none' }} />
            <TooltipIconButton title="Экспортировать" leftIcon={<ExportIcon />} onClick={onClickExport} />
          </FlexContainer>
          <FlexContainer gap="3px" alignItems="center">
            <TooltipIconButton
              title="Синхронизировать параметры образцов"
              leftIcon={<LinkIcon />}
              selected={synchronizationGroupMode}
              onClick={toggleSynchronizationGroupMode}
            />
            <TooltipIconButton
              title="Удалить выделенное"
              leftIcon={<DeleteIcon />}
              disabled={disableDeleteButton}
              onClick={synchronizationGroupMode ? onLocalDeleteGroup : onLocalDeleteColor}
            />
          </FlexContainer>
        </FlexContainer>
      </ElementContainerSettings>
      {isSelectedColor && activeColorData && <HexAndHlsColorEditor value={activeColorData} onChange={onHexAndHlsColor} />}
    </GroupContainerSettings>
  );
};
