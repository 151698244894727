import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { PayloadUpdateLicenseUser, PayloadUpdateRoleUser, PayloadUsersNoLicense } from 'store/reducers/adminLicenses/types';

export const loadAdminLicense = (): Promise<AxiosResponse<FastBoard.API.ApiAdminLicenseResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/license/my`);

export const loadUsersNoLicense = ({
  licenseNum,
}: PayloadUsersNoLicense): Promise<AxiosResponse<FastBoard.API.ApiAdminUserGroupListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/user-groups`, { params: { licenseNum, excludeGroups: true } });

export const uploadLicenseFile = (file: FormData) =>
  axiosClient.post<string, AxiosResponse<string>, FormData>(`/api/v2/admin/license/activate`, file);

export const deleteUserLicense = (userId: string): Promise<AxiosResponse<FastBoard.API.ApiAdminUserLicenseResponseDTO>> =>
  axiosClient.delete(`/api/v2/admin/license/delete-user/${userId}`);

export const createLicenseUser = (userId: string): Promise<AxiosResponse<FastBoard.API.ApiAdminUserLicenseResponseDTO>> =>
  axiosClient.post(`/api/v2/admin/license/add-user/${userId}`);

export const updateLicenseUser = ({ oldUserId, newUserId }: PayloadUpdateLicenseUser) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiAdminUserLicenseResponseDTO>, FastBoard.API.ApiAdminReplaceUserDTO>(
    `/api/v2/admin/license/replace-user`,
    {
      oldUserId,
      newUserId,
    },
  );

export const updateRoleUser = ({ userId, role }: PayloadUpdateRoleUser) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiAdminUserLicenseResponseDTO>, FastBoard.API.ApiAdminUpdateRoleDTO>(
    `/api/v2/admin/license/update/${userId}/role`,
    {
      roleId: role,
    },
  );
