import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getSourcesStore = createSelector(getState, (state) => state.sources);

export const getSources = createSelector(getSourcesStore, (state) => state.sourcesData);
export const getActiveSourceId = createSelector(getSourcesStore, (state) => state.activeSourceId);
export const getSourcesList = createSelector(getSources, (state) => state.sourcesList);

export const getSourcesActiveLoading = createSelector(getSourcesStore, (state) => state.sourcesActiveLoading);

export const getConnectedSources = createSelector(getSourcesList, (state) => state.filter(({ selected }) => selected));

export const getSourceUsersAndGroups = createSelector(getSourcesStore, (state) => state.sourcesUsersAndGroups);

export const getSourceUsersGroups = createSelector(getSourcesStore, (state) => state.usersGroupsList);
