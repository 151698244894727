import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { TState } from 'store/index';
import { TaskInterface } from 'store/reducers/adminTasks/types';
import { clearAstStore } from 'store/reducers/ast/actions';
import { getUserInfo } from 'store/reducers/auth';
import { clearBoardStore, loadLayersAction, loadLayersFromSnapshotAction } from 'store/reducers/board/actions';
import {
  clearFiltersStore,
  loadEnabledFiltersAction,
  loadEnabledFiltersFromSnapshotAction,
  loadFiltersAction,
  loadFiltersByProjectIdAction,
  loadFiltersFromSnapshotAction,
  loadGlobalEnabledFiltersAction,
} from 'store/reducers/filters/actions';
import { clearLoadingScriptStore } from 'store/reducers/loadingScript/actions';
import { loadModelsFromDataAction } from 'store/reducers/models/actions';
import { clearPalettesStore, loadPalettesAction, loadPalettesFromSnapshotAction } from 'store/reducers/palettes/actions';
import { clearProjectPagesStore, loadPagesAction, loadPagesFromSnapshotAction } from 'store/reducers/projectPages/actions';
import { initialProjectSettingsState } from 'store/reducers/projectSettings/constants';
import {
  setProjectTask,
  setSlice,
  updateDashboardComponentRendering,
  updateDefaultModelId,
  updateProjectSettings,
} from 'store/reducers/projectSettings/index';
import {
  getSettingsSnapshot,
  getSettingsSnapshotFromLS,
  SettingsSnapshots,
  SettingsSnapshotType,
} from 'store/reducers/projectSettings/settingsSnapshotService';
import {
  ProjectSettingsActionsTypes,
  ProjectSettingsInterface,
  UpdateProjectSettingsPayload,
  UploadProjectScreenPayload,
} from 'store/reducers/projectSettings/types';
import { clearSourcesStore } from 'store/reducers/sources/actions';
import {
  clearThemesStore,
  loadActiveThemeAction,
  loadActiveThemeFromSnapshotAction,
  loadPaletteLinksAction,
  loadPaletteLinksFromSnapshotAction,
  loadThemesAction,
  loadThemesFromSnapshotAction,
} from 'store/reducers/themes/actions';
import {
  clearVisualisationsStore,
  loadVisualisationsAction,
  loadVisualisationsFromSnapshotAction,
} from 'store/reducers/visualisations/actions';
import { IdInterface, PageIdInterface, ProjectIdInterface, ProjectIdWithType } from 'types/store';
import {
  createProjectTask,
  loadDefaultModelId,
  loadLastSettingsTimestamp,
  loadProjectSettings,
  loadProjectTask,
  updateActiveProjectTask,
  updateProjectTask,
  uploadDashboardSettings,
  uploadProjectScreen,
} from './api';
import { loadFlowProjectInfoAction } from '../projectManager/actions';

/* TODO: Adding types and interface for Error Messages  */

const validateError = (err: AxiosError, rejectWithValue?: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue?.(errorMessage);
};

export const loadProjectSettingsAction = createAsyncThunk<ProjectSettingsInterface, string, { rejectValue: null }>(
  ProjectSettingsActionsTypes.LOAD_PROJECT_SETTINGS,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadProjectSettings(projectId);
      return response.data.projectSettings;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const loadProjectSettingsFromSnapshotAction = createAsyncThunk<ProjectSettingsInterface, SettingsSnapshotType['settings']>(
  ProjectSettingsActionsTypes.LOAD_PROJECT_SETTINGS_FROM_SNAPSHOT,
  (projectSettings) => projectSettings,
);

export const uploadDashboardSettingsAction = createAsyncThunk(
  ProjectSettingsActionsTypes.UPLOAD_DASHBOARD_SETTINGS,
  async (projectId: string, { rejectWithValue, getState, signal }) => {
    try {
      const snapshot = getSettingsSnapshot(getState() as TState);

      const response = await uploadDashboardSettings({ projectId, payload: snapshot }, { signal });
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const loadDefaultModelIdAction = createAsyncThunk<string | null, string>(
  ProjectSettingsActionsTypes.LOAD_DEFAULT_MODEL_ID,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadDefaultModelId(projectId);
      return response.data.projectDefaultModelId;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const loadDefaultModelIdFromSnapshotAction = createAsyncThunk<string | null, SettingsSnapshotType['defaultModelId']>(
  ProjectSettingsActionsTypes.LOAD_DEFAULT_MODEL_ID_FROM_SNAPSHOT,
  (defaultModelId) => defaultModelId,
);

export const updateProjectSettingsAction = createAsyncThunk(
  ProjectSettingsActionsTypes.UPDATE_PROJECT_SETTINGS,
  (projectSettings: UpdateProjectSettingsPayload, { dispatch }) => {
    dispatch(updateProjectSettings(projectSettings));
  },
);

export const updateDashboardComponentRenderingAction = createAsyncThunk(
  ProjectSettingsActionsTypes.UPDATE_DASHBOARD_COMPONENT_RENDERING,
  (dashboardComponentRendering: boolean, { dispatch }) => {
    dispatch(updateDashboardComponentRendering(dashboardComponentRendering));
  },
);

export const updateDefaultModelIdAction = createAsyncThunk(
  ProjectSettingsActionsTypes.UPDATE_DEFAULT_MODEL_ID,
  (defaultModelId: string | null, { dispatch }) => {
    dispatch(updateDefaultModelId(defaultModelId));
  },
);

export const loadFullProjectSettingsAction = createAsyncThunk(
  ProjectSettingsActionsTypes.LOAD_FULL_PROJECT_SETTINGS,
  async (projectId: string, { dispatch }) => {
    await dispatch(loadModelsFromDataAction(projectId));

    await dispatch(loadProjectSettingsAction(projectId));
    await dispatch(loadDefaultModelIdAction(projectId));
  },
);

export const loadThemeSettingsAction = createAsyncThunk(
  ProjectSettingsActionsTypes.LOAD_THEME_SETTINGS,
  async (projectId: string, { dispatch }) => {
    await dispatch(loadThemesAction(projectId));
    await dispatch(loadActiveThemeAction(projectId));
    await dispatch(loadPaletteLinksAction(projectId));
  },
);

export const loadDashboardComponentsAction = createAsyncThunk(
  ProjectSettingsActionsTypes.LOAD_DASHBOARD_COMPONENTS_SETTINGS,
  async (projectId: string, { dispatch }) => {
    await dispatch(loadGlobalEnabledFiltersAction(projectId));
  },
);

export const loadLastSettingsTimestampAction = createAsyncThunk<string, string, { rejectValue: null }>(
  ProjectSettingsActionsTypes.LOAD_LAST_SETTINGS_TIMESTAMP,
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await loadLastSettingsTimestamp(projectId);
      return response.data;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const loadWorkspaceDataAction = createAsyncThunk(
  ProjectSettingsActionsTypes.LOAD_WORKSPACE_DATA,
  async (projectId: string, { dispatch }) => {
    await dispatch(loadFullProjectSettingsAction(projectId));

    await dispatch(loadPalettesAction(projectId));

    await dispatch(loadThemeSettingsAction(projectId));

    await dispatch(loadPagesAction(projectId));

    await dispatch(loadDashboardComponentsAction(projectId));

    await dispatch(loadLastSettingsTimestampAction(projectId));

    await dispatch(loadFlowProjectInfoAction(projectId));
  },
);

export const loadWorkspaceDataFromSnapshotAction = createAsyncThunk(
  ProjectSettingsActionsTypes.LOAD_WORKSPACE_DATA_FROM_SNAPSHOT,
  async (projectId: string, { getState, dispatch }) => {
    const snapshotData = getSettingsSnapshotFromLS(projectId)(getState() as TState);

    if (snapshotData.snapshot) {
      const {
        settings: projectSettings,
        defaultModelId,
        filters,
        enabledFilters,
        layers,
        themes,
        activeThemeId,
        paletteLinks,
        palettes,
        visualisations,
        pages,
      } = snapshotData.snapshot;

      dispatch(loadProjectSettingsFromSnapshotAction(projectSettings));
      dispatch(loadDefaultModelIdFromSnapshotAction(defaultModelId));

      dispatch(loadPalettesFromSnapshotAction(palettes));

      dispatch(loadThemesFromSnapshotAction(themes));
      dispatch(loadActiveThemeFromSnapshotAction(activeThemeId));
      dispatch(loadPaletteLinksFromSnapshotAction(paletteLinks));

      dispatch(loadPagesFromSnapshotAction(pages));

      dispatch(loadFiltersFromSnapshotAction(filters));
      dispatch(loadEnabledFiltersFromSnapshotAction(enabledFilters));

      dispatch(loadVisualisationsFromSnapshotAction(visualisations));

      dispatch(loadLayersFromSnapshotAction(layers));
    }
  },
);

export const uploadWorkspaceDataAction = createAsyncThunk(
  ProjectSettingsActionsTypes.UPLOAD_WORKSPACE_DATA,
  async (projectId: string, { dispatch, signal }) => {
    const uploadDashboardSettings = dispatch(uploadDashboardSettingsAction(projectId || ''));

    signal.addEventListener('abort', () => {
      uploadDashboardSettings.abort();
    });

    return uploadDashboardSettings;
  },
);

export const loadPageDataAction = createAsyncThunk(
  ProjectSettingsActionsTypes.LOAD_PAGE_DATA,
  ({ projectId, pageId }: ProjectIdWithType<PageIdInterface>, { dispatch, signal }) => {
    const layersRequest = dispatch(loadLayersAction({ projectId, pageId }));
    const visualisationsRequest = dispatch(loadVisualisationsAction({ projectId, pageId }));
    const filtersRequest = dispatch(loadFiltersAction({ projectId, pageId }));
    const enabledFiltersRequest = dispatch(loadEnabledFiltersAction({ projectId, pageId }));

    signal.addEventListener('abort', () => {
      layersRequest.abort();
      visualisationsRequest.abort();
      filtersRequest.abort();
      enabledFiltersRequest.abort();
    });

    return Promise.allSettled([layersRequest, visualisationsRequest, filtersRequest, enabledFiltersRequest]);
  },
);

export const loadProjectFiltersAction = createAsyncThunk(
  ProjectSettingsActionsTypes.LOAD_PROJECT_FILTERS,
  ({ projectId }: ProjectIdInterface, { dispatch, signal }) => {
    const allFilters = dispatch(loadFiltersByProjectIdAction({ projectId }));

    signal.addEventListener('abort', () => {
      allFilters.abort();
    });

    return allFilters;
  },
);

export const saveSettingSnapshotToLocalStorage = createAsyncThunk(
  ProjectSettingsActionsTypes.SAVE_SETTINGS_SNAPSHOT,
  (projectId: string, { getState }) => {
    const state = getState() as TState,
      snapshot = getSettingsSnapshot(state),
      { login } = getUserInfo(state);

    if (login) {
      SettingsSnapshots.saveToLocalStorage({ snapshot, projectId, login });
    }
  },
);

export const deleteSettingSnapshotFromLocalStorage = createAsyncThunk(
  ProjectSettingsActionsTypes.DELETE_SETTINGS_SNAPSHOT,
  (projectId: string, { getState }) => {
    const { login } = getUserInfo(getState() as TState);

    if (login) {
      SettingsSnapshots.deleteSnapshot({ projectId, login });
    }
  },
);

export const clearWorkspaceDataAction = createAsyncThunk(ProjectSettingsActionsTypes.CLEAR_WORKSPACE_DATA, (_, { dispatch }) => {
  dispatch(clearThemesStore());
  dispatch(clearPalettesStore());
  dispatch(clearBoardStore());
  dispatch(clearProjectPagesStore());
  dispatch(clearFiltersStore());
  dispatch(clearVisualisationsStore());
  dispatch(clearAstStore());
  dispatch(clearProjectSettingsStore());
  dispatch(clearSourcesStore());
  dispatch(clearSourcesStore());
  dispatch(clearLoadingScriptStore());
});

export const clearProjectSettingsStore = createAsyncThunk(
  ProjectSettingsActionsTypes.CLEAR_PROJECT_SETTINGS_STORE,
  (_, { dispatch }) => {
    dispatch(setSlice(initialProjectSettingsState));
  },
);

export const uploadProjectScreenAction = createAsyncThunk<FastBoard.API.ProjectListItemDTO, UploadProjectScreenPayload>(
  ProjectSettingsActionsTypes.UPLOAD_PROJECT_SCREEN,
  async (params, { rejectWithValue }) => {
    try {
      const response = await uploadProjectScreen(params);

      return response.data.project;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

/* TODO: :Hide the error while the backend makes a normal error */
export const loadProjectTaskAction = createAsyncThunk<TaskInterface, string>(
  ProjectSettingsActionsTypes.LOAD_PROJECT_TASK,
  async (params, { rejectWithValue }) => {
    try {
      const response = await loadProjectTask(params);

      return response.data.cronjobItemWithId;
    } catch (err: any) {
      return rejectWithValue(null);
    }
  },
);

export const createProjectTaskAction = createAsyncThunk<
  TaskInterface,
  FastBoard.API.CronJobCreateDTO & IdInterface,
  { rejectValue: null }
>(ProjectSettingsActionsTypes.CREATE_PROJECT_TASK, async (params, { rejectWithValue }) => {
  try {
    const response = await createProjectTask(params);
    Snackbar.show('Задание создано', 'success');

    return response.data.cronjobItemWithId;
  } catch (err: any) {
    return validateError(err, rejectWithValue);
  }
});

export const updateProjectTaskAction = createAsyncThunk<TaskInterface, FastBoard.API.CronJobCreateDTO & IdInterface>(
  ProjectSettingsActionsTypes.UPDATE_PROJECT_TASK,
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateProjectTask(params);
      Snackbar.show('Задание изменено', 'success');

      return response.data.cronjobItemWithId;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateActiveProjectTaskAction = createAsyncThunk<TaskInterface, FastBoard.API.CronJobCtlDTO & IdInterface>(
  ProjectSettingsActionsTypes.UPDATE_ACTIVE_PROJECT_TASK,
  async (params, { rejectWithValue }) => {
    try {
      const response = await updateActiveProjectTask(params);
      Snackbar.show('Активность задачи изменена', 'success');

      return response.data.cronjobItemWithId;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const setProjectTaskAction = createAsyncThunk<void, TaskInterface>(
  ProjectSettingsActionsTypes.SET_PROJECT_TASK,
  async (task, { dispatch }) => {
    dispatch(setProjectTask(task));
  },
);
