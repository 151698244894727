import {
  GroupItemInterface,
  PaletteColorItemInterface,
  PaletteItemInterface,
  PalettesStateInterface,
} from 'store/reducers/palettes/types';
import { v4 as uuidv4 } from 'uuid';
import { HexAndHLSColorInterface, IdInterface } from 'types/store';
import { PayloadAction } from '@reduxjs/toolkit';

export const initialPalettesStoreState: PalettesStateInterface = {
  palettes: {},
  serverStateOfPalettes: null,
  palettesLoading: false,
};

export const defaultPaletteName = 'Моя палитра ';
export const defaultGroupName = 'Группа ';

export const defaultPaletteColorItem: HexAndHLSColorInterface = {
  hex: '#63E387',
  h: 137,
  s: 70,
  l: 55,
  opacity: 1,
};

export const getPaletteColorItem: (params: Partial<PaletteColorItemInterface>) => PaletteColorItemInterface = ({
  id,
  ...colorsData
}) => ({
  id: id || uuidv4(),
  ...defaultPaletteColorItem,
  ...colorsData,
});

export const getPaletteGroupItem: (params: Partial<GroupItemInterface>) => GroupItemInterface = ({ id, name, colors }) => ({
  id: id || uuidv4(),
  name: name || defaultGroupName + '1',
  colors: colors || [getPaletteColorItem({ id: uuidv4() })],
});

export const getPaletteItem: (params: Partial<PaletteItemInterface>) => PaletteItemInterface = ({
  id,
  name,
  groups,
  basedOn,
}) => ({
  id: id || uuidv4(),
  name: name || defaultPaletteName + '1',
  basedOn: basedOn || null,
  showGroupNames: true,
  groups: groups || [getPaletteGroupItem({ id: uuidv4() })],
});

export const defaultPalettesMock: PaletteItemInterface[] = [
  {
    id: uuidv4(),
    name: 'Моя палитра 1',
    basedOn: null,
    showGroupNames: true,
    groups: [
      {
        id: uuidv4(),
        name: 'Группа 1',
        colors: [
          {
            id: uuidv4(),
            hex: '#63E387',
            h: 137,
            s: 70,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#A6DE53',
            h: 84,
            s: 68,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#E9D820',
            h: 55,
            s: 82,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#E99B47',
            h: 31,
            s: 79,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#E95D6E',
            h: 353,
            s: 76,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#E92095',
            h: 325,
            s: 82,
            l: 55,
            opacity: 1,
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Моя палитра 2',
    basedOn: null,
    showGroupNames: true,
    groups: [
      {
        id: uuidv4(),
        name: 'Группа 1',
        colors: [
          {
            id: uuidv4(),
            hex: '#63E387',
            h: 137,
            s: 70,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#A6DE53',
            h: 84,
            s: 68,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#E9D820',
            h: 55,
            s: 82,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#E99B47',
            h: 31,
            s: 79,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#E95D6E',
            h: 353,
            s: 76,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#E92095',
            h: 325,
            s: 82,
            l: 55,
            opacity: 1,
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Моя палитра 3',
    basedOn: null,
    showGroupNames: true,
    groups: [
      {
        id: uuidv4(),
        name: 'Группа 1',
        colors: [
          {
            id: uuidv4(),
            hex: '#EB2D95',
            h: 327,
            s: 83,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#BC33D4',
            h: 291,
            s: 65,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#8C3ADC',
            h: 270,
            s: 70,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#5D40E3',
            h: 251,
            s: 74,
            l: 57,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#2D46EB',
            h: 232,
            s: 83,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#2D68EB',
            h: 221,
            s: 83,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#2D89EB',
            h: 211,
            s: 83,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#2DABEB',
            h: 200,
            s: 83,
            l: 55,
            opacity: 1,
          },
          {
            id: uuidv4(),
            hex: '#2DCCEB',
            h: 190,
            s: 83,
            l: 55,
            opacity: 1,
          },
        ],
      },
    ],
  },
];

export const removePaletteByIdFunc = (state: PalettesStateInterface, { payload: { id } }: PayloadAction<IdInterface>) => {
  if (state.palettes[id]) {
    delete state.palettes[id];
  }
};

export const updatePaletteByIdFunc = (
  state: PalettesStateInterface,
  { payload: { id, ...data } }: PayloadAction<PaletteItemInterface>,
) => {
  const palette = state.palettes[id];

  if (palette) {
    state.palettes[id] = { ...palette, ...data };
  }
};
