import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialSourcesStoreState } from 'store/reducers/sources/constants';
import {
  createConnectionAction,
  deleteScriptSourceByIdAction,
  deleteSourceByIdAction,
  loadSourcesAction,
  loadSourcesActiveLoadingAction,
  loadSourceUsersAndGroupsAction,
  updateConnectionAction,
} from 'store/reducers/sources/actions';
import { SourceDataInterface } from 'store/reducers/sources/types';
import { setSliceFn } from 'constants/store';
import { loadUsersGroupsAction } from 'store/reducers/projectManager/actions';
import { IdInterface } from 'types/store';
import { AccessInterface } from 'types/types';

export const sourcesSlice = createSlice({
  name: 'sources',
  initialState: initialSourcesStoreState,
  reducers: {
    addConnection: (state, { payload: data }: PayloadAction<SourceDataInterface>) => {
      state.sourcesData.sourcesList = [data, ...state.sourcesData.sourcesList];
    },
    deleteByIdSource: (state, { payload: id }: PayloadAction<string>) => {
      state.sourcesData.sourcesList = state.sourcesData.sourcesList.filter((page) => page.id !== id);
    },
    updateSources: (state, { payload: source }: PayloadAction<SourceDataInterface[]>) => {
      state.sourcesData.sourcesList = source;
    },
    deleteByIdScriptSource: (state, { payload: source }: PayloadAction<SourceDataInterface[]>) => {
      state.sourcesData.sourcesList = source;
    },
    changeActiveSourceId: (state, { payload: activeSourceId }: PayloadAction<string | null>) => {
      state.activeSourceId = activeSourceId;
    },
    deleteByIdSourceAccess: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = state.sourcesUsersAndGroups.sourcesUsersAndGroupsList.filter(
        (usersAndGroups) => usersAndGroups.id !== id,
      );
    },
    updateSourceAccess: (state, { payload: access }: PayloadAction<AccessInterface[]>) => {
      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = access;
    },
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadSourcesAction.pending, (state) => {
      state.sourcesData.loading = true;
      state.sourcesData.sourcesList = [];
    });
    builder.addCase(loadSourcesAction.fulfilled, (state, { payload }) => {
      state.sourcesData.loading = false;
      state.sourcesData.sourcesList = payload;
    });
    builder.addCase(loadSourcesAction.rejected, (state) => {
      state.sourcesData.loading = false;
      state.sourcesData.sourcesList = [];
    });

    builder.addCase(createConnectionAction.pending, (state) => {
      state.sourcesActiveLoading = [];
    });
    builder.addCase(createConnectionAction.fulfilled, (state, { payload: { id, taskId } }) => {
      const isTaskIdExists = state.sourcesActiveLoading.some((item) => item.taskId === taskId);

      if (taskId && !isTaskIdExists) {
        state.sourcesActiveLoading = [...state.sourcesActiveLoading, { taskId, sourceId: id }];
      }
    });
    builder.addCase(createConnectionAction.rejected, (state) => {
      state.sourcesActiveLoading = [];
    });

    builder.addCase(updateConnectionAction.pending, (state) => {
      state.sourcesActiveLoading = [];
    });
    builder.addCase(updateConnectionAction.fulfilled, (state, { payload: { id, taskId } }) => {
      const isTaskIdExists = state.sourcesActiveLoading.some((item) => item.taskId === taskId);

      if (taskId && !isTaskIdExists) {
        state.sourcesActiveLoading = [...state.sourcesActiveLoading, { taskId, sourceId: id }];
      }
    });
    builder.addCase(updateConnectionAction.rejected, (state) => {
      state.sourcesActiveLoading = [];
    });

    builder.addCase(deleteSourceByIdAction.pending, (state) => {
      state.sourcesData.loading = true;
    });
    builder.addCase(deleteSourceByIdAction.fulfilled, (state, { payload }) => {
      state.sourcesData.sourcesList = state.sourcesData.sourcesList.filter((source) => source.id !== payload);
      state.sourcesData.loading = false;
    });
    builder.addCase(deleteSourceByIdAction.rejected, (state) => {
      state.sourcesData.loading = false;
    });

    builder.addCase(deleteScriptSourceByIdAction.pending, (state) => {
      state.sourcesData.loading = true;
    });
    builder.addCase(deleteScriptSourceByIdAction.fulfilled, (state, { payload }) => {
      state.sourcesData.sourcesList = state.sourcesData.sourcesList.filter((source) => source.id !== payload);
      state.sourcesData.loading = false;
    });
    builder.addCase(deleteScriptSourceByIdAction.rejected, (state) => {
      state.sourcesData.loading = false;
    });

    builder.addCase(loadSourcesActiveLoadingAction.pending, (state) => {
      state.sourcesActiveLoading = [];
    });
    builder.addCase(loadSourcesActiveLoadingAction.fulfilled, (state, { payload }) => {
      state.sourcesActiveLoading = payload;
    });
    builder.addCase(loadSourcesActiveLoadingAction.rejected, (state) => {
      state.sourcesActiveLoading = [];
    });

    builder.addCase(loadUsersGroupsAction.pending, (state) => {
      state.usersGroupsList.loading = true;
      state.usersGroupsList.usersGroupsList = [];
    });
    builder.addCase(loadUsersGroupsAction.fulfilled, (state, { payload }) => {
      state.usersGroupsList.loading = false;
      state.usersGroupsList.usersGroupsList = payload;
    });
    builder.addCase(loadUsersGroupsAction.rejected, (state) => {
      state.usersGroupsList.loading = false;
      state.usersGroupsList.usersGroupsList = [];
    });

    builder.addCase(loadSourceUsersAndGroupsAction.pending, (state) => {
      state.sourcesUsersAndGroups = { ...state.sourcesUsersAndGroups, loading: true };
      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = [];
    });
    builder.addCase(loadSourceUsersAndGroupsAction.fulfilled, (state, { payload }) => {
      state.sourcesUsersAndGroups = { ...state.sourcesUsersAndGroups, loading: false };

      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = payload;
    });
    builder.addCase(loadSourceUsersAndGroupsAction.rejected, (state) => {
      state.sourcesUsersAndGroups = { ...state.sourcesUsersAndGroups, loading: false };
      state.sourcesUsersAndGroups.sourcesUsersAndGroupsList = [];
    });
  },
});

export const {
  updateSources,
  addConnection,
  deleteByIdSource,
  deleteByIdScriptSource,
  changeActiveSourceId,
  deleteByIdSourceAccess,
  updateSourceAccess,
  setSlice,
} = sourcesSlice.actions;

export default sourcesSlice.reducer;
