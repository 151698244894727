import { AvatarType, EntityType, GroupType, IdInterface, LoadingInterface } from 'types/store';
import { AccessInterface } from 'types/types';

export interface FlowsListInterface extends IdInterface {
  name: string;
  groupType?: GroupType;
}

export interface FlowsInterface extends LoadingInterface {
  flowsList: FlowsListInterface[];
}

export interface FlowListInterface extends IdInterface {
  title: string;
  createdAt: string;
  firstPage: string | null;
  isProtected: boolean;
  avatar: string | null;
  updatedAt?: string | null;
}

export interface FlowShortListInterface extends IdInterface {
  title: string;
}

export interface FlowProjectsInterface extends LoadingInterface {
  flowProjectsList: FlowListInterface[];
}

export interface UsersGroupsInterface extends IdInterface {
  name: string;
  entity: EntityType;
}

export interface FlowAccessInterface extends IdInterface {
  name: string;
  entity: EntityType;
  type: GroupType;
}

export interface FlowAccessesInterface extends LoadingInterface {
  flowAccessList: FlowAccessInterface[];
}

export interface UsersGroupsListInterface extends LoadingInterface {
  usersGroupsList: UsersGroupsInterface[];
}

export interface ProjectAvatarInterface {
  type: AvatarType;
  value: string;
}

export interface CreatedAndUpdatedInterface {
  dateAt: string;
  userName: string;
}

export interface FlowProjectInfoInterface {
  id: string;
  title: string;
  createdAt: string;
  isProtected: boolean;
  updatedDataAt: string | null;
  flow?: {
    id: string;
    name: string;
    isDraft: boolean;
  };
  avatar?: ProjectAvatarInterface;
  updated: CreatedAndUpdatedInterface;
  created: CreatedAndUpdatedInterface;
}

export interface FlowProjectHistoryVersion {
  id: string;
  dateTimeHistory: string;
  userName: string;
  storageMethod: string;
}

export interface FlowProjectHistoryVersionInterface extends LoadingInterface {
  flowProjectHistoryVersionList: FlowProjectHistoryVersion[];
}

export interface ActiveFlowInterface {
  id: string;
  title: string;
  groupType?: GroupType;
}

export type SortType = 'lastModified' | 'alphabet';
export type SortOrderType = 'ascending' | 'descending';

export interface SortProject {
  sortOrder: SortOrderType;
  sortType: SortType;
}

export interface ProjectManagerSliceStateInterface {
  flows: FlowsInterface;
  flow: FlowProjectsInterface;
  draftFlow: FlowProjectsInterface;
  activeFlow: ActiveFlowInterface;
  activeProjectId: string | null;
  flowProjectInfo: FlowProjectInfoInterface | null;
  flowProjectHistoryVersion: FlowProjectHistoryVersionInterface;
  flowDraftInfo: FlowsListInterface | null;
  flowAccesses: FlowAccessesInterface;
  usersGroupsList: UsersGroupsListInterface;
  shortProject: SortProject;
  meta: null | FlowProjectInfoInterface;
}

/* Action Types */
export enum ProjectManagerActionsTypes {
  LOAD_FLOWS = 'LOAD_FLOWS',
  CLEAR_FLOWS = 'CLEAR_FLOWS',
  LOAD_FLOW = 'LOAD_FLOW',
  LOAD_MOVE_FLOW = 'LOAD_MOVE_FLOW',
  LOAD_SHORT_FLOW = 'LOAD_SHORT_FLOW',
  LOAD_DRAFT_FLOW = 'LOAD_DRAFT_FLOW',
  LOAD_FLOW_DRAFT_INFO = 'LOAD_FLOW_DRAFT_INFO',
  LOAD_FLOW_PROJECT_INFO = 'LOAD_FLOW_PROJECT_INFO',
  LOAD_FLOW_PROJECT_HISTORY_VERSION = 'LOAD_FLOW_PROJECT_HISTORY_VERSION',
  LOAD_USERS_GROUPS_LIST = 'LOAD_USERS_GROUPS_LIST',
  ACTIVE_FLOW_ID = 'ACTIVE_FLOW_ID',
  DELETE_FLOW = 'DELETE_FLOW',
  PROTECT_PROJECT = 'PROTECT_PROJECT',
  DELETE_BY_ID_FLOW = 'DELETE_BY_ID_FLOW',
  UPDATE_FLOW_NAME = 'UPDATE_FLOW_NAME',
  CREATE_FLOW = 'CREATE_FLOW',
  CREATE_FLOW_PROJECT = 'CREATE_FLOW_PROJECT',
  RESTORE_HISTORY_PROJECT = 'RESTORE_HISTORY_PROJECT',
  CREATE_PROJECT_VERSION_ANOTHER = 'CREATE_PROJECT_VERSION_ANOTHER',
  COPY_PROJECT_VERSION_ANOTHER = 'COPY_PROJECT_VERSION_ANOTHER',
  MOVE_PROJECT = 'MOVE_PROJECT',
  UPDATE_FLOW_BY_ID = 'UPDATE_FLOW_BY_ID',
  UPDATE_FLOW_PROJECT_BY_ID = 'UPDATE_FLOW_PROJECT_BY_ID',
  UPDATE_DRAFT_FLOW_PROJECT_BY_ID = 'UPDATE_DRAFT_FLOW_PROJECT_BY_ID',
  DELETE_BY_ID_FLOW_PROJECT = 'DELETE_BY_ID_FLOW_PROJECT',
  DELETE_BY_ID_DRAFT_PROJECT = 'DELETE_BY_ID_DRAFT_PROJECT',
  RENAME_PROJECT = 'RENAME_PROJECT',
  DELETE_FLOW_PROJECT = 'DELETE_FLOW_PROJECT',
  ADD_FLOW = 'ADD_FLOW',
  LOAD_FLOW_ACCESSES = 'LOAD_FLOW_ACCESSES',
  ADD_USER_GROUP_FLOW_ACCESS = 'ADD_USER_GROUP_FLOW_ACCESS',
  CHANGE_GROUP_FLOW_ACCESS_TYPE = 'CHANGE_GROUP_FLOW_ACCESS_TYPE',
  CHANGE_USER_FLOW_ACCESS_TYPE = 'CHANGE_USER_FLOW_ACCESS_TYPE',
  DELETE_GROUP_FLOW_ACCESS_TYPE = 'DELETE_GROUP_FLOW_ACCESS_TYPE',
  DELETE_USER_FLOW_ACCESS_TYPE = 'DELETE_USER_FLOW_ACCESS_TYPE',
  DELETE_BY_ID_FLOW_ACCESS = 'DELETE_BY_ID_FLOW_ACCESS',
  UPDATE_FLOW_ACCESS = 'UPDATE_FLOW_ACCESS',
  UPLOAD_PROJECT_AVATAR = 'UPLOAD_PROJECT_AVATAR',
  EXPORT_PROJECT = 'EXPORT_PROJECT',
  IMPORT_PROJECT = 'IMPORT_PROJECT',
  UPDATE_META = 'UPDATE_META',
}

/* Payload Interfaces */

export interface UpdateFlowNamePayload {
  flowId: string;
  name: string;
}

export interface UpdateFlowByIdPayload {
  flow: FlowsListInterface;
}

export interface UpdateFlowProjectByIdPayload {
  flowProject: FlowListInterface;
}

export interface DeleteGroupFlowAccessByIdPayload {
  flowId: string;
  groupId: string;
}

export interface DeleteUserFlowAccessByIdPayload {
  flowId: string;
  userId: string;
}

export interface AddUsersGroupsFlowAccessPayload {
  flowId: string;
  groups?: string[];
  users?: string[];
  type: 'owner' | 'reader';
}

export interface UpdateFlowAccessByIdPayload {
  flowAccess: AccessInterface;
}

interface UpdateFlowAccess {
  flowId: string;
  type: 'owner' | 'reader';
}

export interface UpdateGroupFlowAccessByIdPayload extends UpdateFlowAccess {
  groupId: string;
}

export interface UpdateUserFlowAccessByIdPayload extends UpdateFlowAccess {
  userId: string;
}

export interface LoadUsersGroupsPayload {
  sourceId?: string;
  groupId?: string;
  userId?: string;
  flowId?: string;
  search?: string;
  excludeGroups?: boolean;
  excludeUsers?: boolean;
}

export interface RestoreHistoryProjectPayload {
  projectId: string;
  dateTimeHistory: string;
}

export interface CreateProjectVersionAnotherPayload {
  projectId: string;
  name: string;
  flowId: string;
  dateTimeHistory?: string;
  runImport?: boolean;
}

export interface CopyProjectVersionAnotherPayload {
  projectFromId: string;
  projectToId: string;
  runImport?: boolean;
}

export interface MoveProjectPayload {
  flowId: string;
  projectId: string;
}

export interface ProtectProjectPayload {
  projectId: string;
  isProtected: boolean;
}

export interface RenameProjectPayload {
  id: string;
  name: string;
}

export interface ChangeActiveFlowPayload {
  id: string;
  title: string;
  groupType?: GroupType;
}

export type UploadProjectAvatarPayload = {
  projectId: string;
  avatar: FormData;
};
