import { Color } from 'modules/settingsContainer/ColorPicker/styles';
import { ColorGrid, ColorGridProps } from './styles';
import { isValidHex } from 'utils/formatting';
import { v4 } from 'uuid';
import { ColorsInterface } from './types';

export interface ColorTransparentProps extends ColorGridProps {
  onClick?: () => void;
}

export const ColorTransparent = ({ color, onClick, colorSize, active }: ColorTransparentProps) => {
  const transparentColor = isValidHex(color) ? color.slice(0, 7) : color;
  const colors: ColorsInterface[] = [
    { color, id: v4() },
    { color: transparentColor, id: v4() },
    { color: transparentColor, id: v4() },
    { color, id: v4() },
  ];

  return (
    <ColorGrid colorSize={colorSize} color={color} active={active} onClick={onClick}>
      {colors.map(({ color, id }) => (
        <Color key={id} color={color} colorSizePercent="100%" active={false} />
      ))}
    </ColorGrid>
  );
};
