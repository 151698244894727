import { ActiveColorInterface } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/types';
import { ActiveColorItemInterface, GroupItemInterface } from 'store/reducers/palettes/types';

export const defaultActiveColor: ActiveColorInterface = { activeColorIds: null, colorIndex: null };

export const exportFlowers = 'exportFlowers';

export const isActiveColor = (
  checkedActiveColorIds: ActiveColorItemInterface | null,
  activeColorIds: ActiveColorItemInterface | null,
) => activeColorIds?.colorId === checkedActiveColorIds?.colorId && activeColorIds?.groupId == checkedActiveColorIds?.groupId;

//Функция используется что бы найти количество элементов с одинаковым названием для того что бы задать номер копии
export const findCopyIndex = (names: string[], baseName: string) => {
  const matchingNames = names.filter((name) => {
    const basePattern = new RegExp(`^${baseName} \\(\\d+\\)$`);
    return basePattern.test(name);
  });

  const indices = matchingNames.map((name) => {
    const match = name.match(/\((\d+)\)$/);
    return match ? parseInt(match[1], 10) : 0;
  });

  return indices.length ? Math.max(...indices) + 1 : 1;
};

//Функция используеться для нахождение индекса последнего похожего по названию элемента исключая ...(number).
export const findLastCopyIndex = (groups: GroupItemInterface[], baseName: string) => {
  const lastCopyIndex = groups.reduce((lastIndex, group, index) => {
    const basePattern = new RegExp(`^${baseName} \\(\\d+\\)$`);
    if (basePattern.test(group.name) || group.name === baseName) {
      return index;
    }
    return lastIndex;
  }, -1);

  return lastCopyIndex;
};

export const exportJsonFile = (data: string, nameFile: string) => {
  const blob = new Blob([data], { type: 'application/json' });

  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${nameFile}_fastboard.json`;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const changePaletteColor = ({
  replaceColors,
  groups,
  oldGroups,
}: {
  replaceColors: boolean;
  groups: GroupItemInterface[];
  oldGroups: GroupItemInterface[];
}) => {
  if (!groups.length || !oldGroups.length) {
    return oldGroups;
  }

  const newGroups = groups.filter((group) => !oldGroups.some((activeGroup) => group.id === activeGroup.id));

  const updatedGroups = oldGroups.map((oldGroup) => {
    const newGroup = groups.find((group) => group.id === oldGroup.id);

    if (!newGroup) return oldGroup;

    if (!replaceColors) {
      // Возвращаем все старые группы с новыми, не существующими цветами
      const newColors = newGroup.colors.filter((newColor) => !oldGroup.colors.some((oldColor) => newColor.id === oldColor.id));
      return { ...oldGroup, colors: [...oldGroup.colors, ...newColors] };
    }

    const oldGroupColors = oldGroup.colors.map((oldColor) => {
      const newColor = newGroup.colors.find((color) => color.id === oldColor.id);
      return newColor ? newColor : oldColor;
    });

    const newColors = newGroup.colors.filter((newColor) => !oldGroup.colors.some((oldColor) => newColor.id === oldColor.id));

    return { ...oldGroup, colors: [...oldGroupColors, ...newColors] };
  });

  return [...updatedGroups, ...newGroups];
};
