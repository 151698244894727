import { IdInterface, UsersAndGroupInterface } from 'types/store';
import { MoveToType } from 'utils/utils';

export interface PageBoardSettingsSizesInterface {
  height: number;
  width: number;
}

export interface PageBoardSettingsInterface {
  sizes: PageBoardSettingsSizesInterface;
}

export interface PageInterface extends IdInterface {
  name: string;
  isHidden: boolean;
  boardSettings: PageBoardSettingsInterface;
}

export interface PagesStateInterface {
  projectPages: PageInterface[];
  serverStateOfProjectPages: PageInterface[] | null;
  firstPageId: string | null;
  activePageId: string;
  pagesLoading: boolean;
  rlsUserGroupAnfGroup: UsersAndGroupInterface[];
}

/* Action Types */

export enum PagesActionsTypes {
  UPLOAD_PAGES_POSITION = 'UPLOAD_PAGES_POSITION',
  ADD_PAGE = 'ADD_PAGE',
  DELETE_PAGE = 'DELETE_PAGE',
  LOAD_PAGES = 'LOAD_PAGES',
  LOAD_PAGES_FROM_SNAPSHOT = 'LOAD_PAGES_FROM_SNAPSHOT',
  LOAD_FIRST_PAGE_ID = 'LOAD_FIRST_PAGE_ID',
  LOAD_FIRST_BY_PAGE_ID = 'LOAD_FIRST_BY_PAGE_ID',
  COPY_PAGE = 'COPY_PAGE',
  ADD_PAGE_BY_DATA = 'ADD_PAGE_BY_DATA',
  CLEAR_PROJECT_PAGES_STORE = 'CLEAR_PROJECT_PAGES_STORE',
  UPDATE_PAGE_BY_ID = 'UPDATE_PAGE_BY_ID',
  LOAD_RLS_USER_AND_GROUP = 'LOAD_RLS_USER_AND_GROUP',
  EXPORT_PAGES_ACTION = 'EXPORT_PAGES_ACTION',
  IMPORT_PAGES_ACTION = 'IMPORT_PAGES_ACTION',
}

export interface UpdatePageByIdPayload extends IdInterface {
  page: Omit<PageInterface, 'id'>;
}

export interface UpdatePagePositionPayload extends IdInterface {
  moveTo: MoveToType;
}

export interface ExportPagesInterface {
  projectId: string;
  data: FastBoard.API.ExportAndImportPagesDto;
}

export interface ImportPagesInterface<TypeFile> {
  projectId: string;
  file: TypeFile;
  importModels: boolean;
  newPages: boolean;
}
