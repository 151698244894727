const API = process.env.REACT_APP_API_URL || 'https://fastbord-back-dev.fb-dev.winsolutions.ru';
/* TODO Добавить env через Андрея, перед тем, как выливать это в дев для тестирования */
const WEB_SOCKET_CONNECT_URL = 'wss://face-detection-dev3.fb-dev.winsolutions.ru/ws';
const VERSION = process.env.REACT_APP_VERSION || '1.0.0';

const API_HOST = `${API}`;

const env = {
  API,
  API_HOST,
  VERSION,
  WEB_SOCKET_CONNECT_URL,
};

export default env;
