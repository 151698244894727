import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { SortingPanel } from 'components/shared/SortingPanel';
import { Button, Checkbox } from 'modules/ui';
import { CheckedValueInterface } from './types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import snackbar from 'services/Snackbar';
import { NoopType, NoopValueType } from 'types/global';

interface ExportDataInProjectProps {
  title: string;
  errorMessage: string;
  value: CheckedValueInterface[];
  onChange: NoopValueType<string[]>;
  onCancel: NoopType;
  children?: ReactNode;
  switchTitle?: string;
}

export const ExportDataInProject: FC<ExportDataInProjectProps> = ({
  title,
  errorMessage,
  value,
  onChange,
  onCancel,
  children,
}) => {
  const [exportData, setExportData] = useState<CheckedValueInterface[]>(value);

  const onSelectAllClick = useCallback(() => {
    setExportData((prev) => {
      return prev.map((el) => ({
        ...el,
        isChecked: true,
      }));
    });
  }, []);

  const onSelectInvertClick = useCallback(() => {
    setExportData((prev) => {
      return prev.map((el) => ({
        ...el,
        isChecked: !el.isChecked,
      }));
    });
  }, []);

  const onSelectNoneClick = useCallback(() => {
    setExportData((prev) => {
      return prev.map((el) => ({
        ...el,
        isChecked: false,
      }));
    });
  }, []);

  const checkedValue = (index: number, isChecked: boolean) => {
    setExportData((prev) => {
      return prev.map((item, i) => (i === index ? { ...item, isChecked } : item));
    });
  };

  const onClickNext = useCallback(() => {
    const newArray = exportData.filter(({ isChecked }) => isChecked).map(({ id }) => id);

    if (!newArray.length) {
      snackbar.show(errorMessage, 'error');
      return;
    }

    onChange(newArray);
    onCancel();
  }, [errorMessage, exportData, onCancel, onChange]);

  return (
    <FlexContainer flexDirection="column" gap="16px" width="100%">
      <FlexContainer justifyContent="center">
        <PrimaryTextSpan fontSize="14px" textAlign="center" color={`var(${ColorVarsEnum.Level_1})`} fontWeight="bold">
          {title}
        </PrimaryTextSpan>
      </FlexContainer>
      <FlexContainer justifyContent="flex-end">
        <SortingPanel
          onSelectAllClick={onSelectAllClick}
          onSelectInvertClick={onSelectInvertClick}
          onSelectNoneClick={onSelectNoneClick}
        />
      </FlexContainer>
      <FlexContainer flexDirection="column" gap="16px" overflow="auto">
        {exportData.map(({ name, id, isChecked }, index) => (
          <Checkbox
            key={id}
            padding="0"
            name="isLicense"
            checked={isChecked}
            label={name}
            id="license"
            onChange={() => checkedValue(index, !isChecked)}
          />
        ))}
      </FlexContainer>
      {children && <FlexContainer>{children}</FlexContainer>}
      <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
        <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onCancel} />
        <Button text="Продолжить" iconSize="normal" heightSize="normal" onClick={onClickNext} />
      </FlexContainer>
    </FlexContainer>
  );
};
