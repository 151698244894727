import { IdInterface, LoadingInterface, NameInterface } from 'types/store';
import { MaskMultiFilePatternType } from 'components/console/elements/sideBar/Forms/FileForm/types';
import { UsersGroupsListInterface } from 'store/reducers/projectManager/types';
import { AccessInterface } from 'types/types';

export type SourceConnectionKind = 'database' | 'file';
export type SourceDriverDataBaseType = 'clickHouse' | 'postgres' | 'ms' | 'my' | 'oracle' | 'hive';
export type SourceDriverFileType = 'qvd' | 'xlsx' | 'csv' | 'txt' | 'json' | 'xml';

export interface SourceDataInterface extends IdInterface, NameInterface {
  type: 'database' | 'file' | 'rest_api';
  driver: string;
  createdAt?: string;
  updatedAt?: string;
  selected?: boolean;
  filePath?: string;
  fileName?: string;
  isValid: boolean;
}

export type SourceDriver<SourceConnectionType extends SourceConnectionKind> = SourceConnectionType extends 'database'
  ? SourceDriverDataBaseType
  : SourceConnectionType extends 'file'
  ? SourceDriverFileType
  : never;

export type SourceConnectionData<SourceConnectionType extends SourceConnectionKind> = SourceConnectionType extends 'database'
  ? SourceConnectionDataBase
  : SourceConnectionType extends 'file'
  ? SourceFileConnectionData
  : never;

export interface SourceFileConnectionData {
  filePathLocal?: string;
  filePath?: string;
  nameTable?: string;
  delimiter?: (string | undefined)[];
}

export interface SourceConnectionDataBase {
  host: string;
  port: number;
  database: string;
  user: string;
  password: string;
  https?: boolean;
}

export interface SourceConnection<SourceConnectionType extends SourceConnectionKind> {
  name: string;
  driverId: SourceDriver<SourceConnectionType>;
  credentials: SourceConnectionData<SourceConnectionType>;
  autoUpdate?: boolean;
}

export interface UpdateConnection<SourceConnectionType extends SourceConnectionKind> extends IdInterface {
  sourceData: SourceConnection<SourceConnectionType>;
}

export interface Tables {
  name: string;
  cellSkip?: number;
  rowSkip?: number;
}

export interface MaskMultiFileInterface {
  value?: string;
  pattern?: MaskMultiFilePatternType;
}

export interface SourceFileConnectionData {
  filePathLocal?: string;
  filePath?: string;
  nameTable?: string;
  delimiter?: (string | undefined)[];
  manyfiles?: boolean;
  mask?: MaskMultiFileInterface;
}

export interface SourceConnectionDataBase {
  host: string;
  port: number;
  database: string;
  user: string;
  password: string;
  https?: boolean;
  dataFormat?: string;
}

export interface CheckingConnectionDataBaseData {
  driver: SourceDriverDataBaseType;
  credentials: SourceConnectionDataBase;
}

export interface SourcesInterface extends LoadingInterface {
  sourcesList: SourceDataInterface[];
}

export interface ActiveSourceInterface extends LoadingInterface {
  activeSourceId: string | null;
  taskId?: string;
}

export interface SourcesActiveLoading {
  sourceId: string;
  taskId: string;
}

export interface SourcesUsersAndGroupsInterface extends LoadingInterface {
  sourcesUsersAndGroupsList: AccessInterface[];
}

export interface SourcesStateInterface {
  sourcesData: SourcesInterface;
  sourcesActiveLoading: SourcesActiveLoading[];
  usersGroupsList: UsersGroupsListInterface;
  sourcesUsersAndGroups: SourcesUsersAndGroupsInterface;
  activeSourceId: string | null;
}

/* Action Types */

export enum SourcesActionsTypes {
  UPLOAD_SOURCES_FILE = 'UPLOAD_SOURCES_FILE',
  UPLOAD_SOURCES = 'UPLOAD_SOURCES',
  DELETE_SOURCE = 'DELETE_SOURCE',
  DELETE_SOURCE_SCRIPT = 'DELETE_SOURCE_SCRIPT',
  DELETE_BY_ID_SOURCE = 'DELETE_BY_ID_SOURCE',
  ACTIVE_SOURCE_ID = 'ACTIVE_SOURCE_ID',
  DELETE_BY_ID_SCRIPT_SOURCE = 'DELETE_BY_ID_SCRIPT_SOURCE',
  LOAD_SOURCES = 'LOAD_SOURCES',
  LOAD_FILES_PATH = 'LOAD_FILES_PATH',
  LOAD_SOURCE_BY_NAME = 'LOAD_SOURCE_BY_NAME',
  LOAD_SOURCE_FILE_STATUS = 'LOAD_SOURCE_FILE_STATUS',
  LOAD_SOURCE_FILE_TASK = 'LOAD_SOURCE_FILE_TASK',
  CREATE_CONNECTION = 'CREATE_CONNECTION',
  UPLOAD_FILE = 'UPLOAD_FILE',
  UPDATE_CONNECTION = 'UPDATE_CONNECTION',
  CLEAR_STATUS_LOADING = 'CLEAR_STATUS_LOADING',
  LOAD_SOURCES_ACTIVE_LOADING = 'LOAD_SOURCES_ACTIVE_LOADING',
  CLEAR_SOURCES = 'CLEAR_SOURCES',
  LOAD_SOURCES_USER_AND_GROUP = 'LOAD_SOURCES_USER_AND_GROUP',
  ADD_SOURCE_USER_GROUP_ACCESS = 'ADD_SOURCE_USER_GROUP_ACCESS',
  DELETE_GROUP_SOURCE_ACCESS_TYPE = 'DELETE_GROUP_SOURCE_ACCESS_TYPE',
  DELETE_USER_SOURCE_ACCESS_TYPE = 'DELETE_USER_SOURCE_ACCESS_TYPE',
  UPDATE_SOURCE_ACCESS_USER = 'UPDATE_SOURCE_ACCESS_USER',
  UPDATE_SOURCE_ACCESS_CROUP = 'UPDATE_SOURCE_ACCESS_CROUP',
  UPDATE_SOURCE_ACCESS = 'UPDATE_SOURCE_ACCESS',
  DELETE_BY_ID_SOURCE_ACCESS = 'DELETE_BY_ID_SOURCE_ACCESS',
}

/* Payload */

export interface UpdateSourceByIdPayload extends IdInterface {
  source: Omit<SourceDataInterface, 'id'>;
}
