import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import {
  AllModelRulesPayload,
  CreateModelRulePayload,
  ExcludeGroupsAndUsersPayload,
  ImportingUsersAndGroupsRulePayload,
  LoadExportingUsersAndGroupsPayload,
  LoadMetaModelsPayloads,
  LoadTablePreviewPayloads,
  ModelRulePayload,
  TableFieldResponse,
  UpdateModelRuleNamePayload,
} from 'store/reducers/models/types';

export const uploadModels = ({ projectId, payload }: { projectId: string; payload: FastBoard.API.ProjectModelsUpdateDTO }) =>
  axiosClient.post<string, AxiosResponse<string>, FastBoard.API.ProjectModelsUpdateDTO>(
    `/api/v2/project/${projectId}/models`,
    payload,
  );

export const uploadMetaModels = ({ projectId, payload }: { projectId: string; payload: FastBoard.API.ProjectMetaModelsDTO }) =>
  axiosClient.post<string, AxiosResponse<string>, LoadMetaModelsPayloads>(`/api/v2/project/${projectId}/meta-models`, payload);

export const loadModels = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectModelsResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/models`);

export const loadMetaModels = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectMetaModelsResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/meta-models`);

export const loadModelsFromData = (projectId: string): Promise<AxiosResponse<FastBoard.API.ModelsFromResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/model/from`);

/* TODO: Adding type for payload and response */
export const loadTableFields = (projectId: string): Promise<AxiosResponse<TableFieldResponse>> =>
  axiosClient.post(`/api/v1/models/get_fields`, { project: projectId });

export const loadTablePreview = ({
  projectId,
  table,
}: LoadTablePreviewPayloads): Promise<AxiosResponse<FastBoard.API.ClickHouseResponse>> =>
  axiosClient.get(`/api/v1/projects/${projectId}/table/${table}`);

export const loadAllModelRules = ({
  projectId,
  modelId,
}: AllModelRulesPayload): Promise<AxiosResponse<FastBoard.API.ApiShortModelRulesResponse>> =>
  axiosClient.get(`/api/v2/model-rule/project/${projectId}/model/${modelId}/all`);

export const loadModelRule = ({
  ruleId,
  projectId,
}: ModelRulePayload): Promise<AxiosResponse<FastBoard.API.ApiModelRuleResponse>> =>
  axiosClient.get(`/api/v2/model-rule/project/${projectId}/rule/${ruleId}`);

export const deleteModelRule = ({ ruleId, projectId }: ModelRulePayload): Promise<boolean> =>
  axiosClient.delete(`/api/v2/model-rule/project/${projectId}/rule/${ruleId}`);

export const createModelRule = ({ name, file, projectId, modelId }: CreateModelRulePayload) => {
  const newFormData = new FormData();
  file && newFormData.append('users_and_groups', file);
  newFormData.append('name', name);

  return axiosClient.post<string, AxiosResponse<FastBoard.API.ApiModelRuleResponse>, FormData>(
    `/api/v2/model-rule/project/${projectId}/model/${modelId}/create/from/file`,
    newFormData,
  );
};

export const updateModelRuleName = ({ projectId, name, id }: UpdateModelRuleNamePayload) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiModelRuleResponse>, FastBoard.API.UpdateRuleNameDTO>(
    `/api/v2/model-rule/project/${projectId}/rule/name`,
    { name, id },
  );

export const loadUsersNoGroup = ({
  excludeGroups,
  excludeUsers,
}: ExcludeGroupsAndUsersPayload): Promise<AxiosResponse<FastBoard.API.UserGroupListResponseDTO>> =>
  axiosClient.get(`/api/v1/user-groups`, { params: { excludeGroups, excludeUsers } });

export const updateRuleModel = ({
  data: { modelId, name, id, isActive, query, variableNames, users, groups },
  projectId,
}: {
  data: FastBoard.API.CreateAndUpdateRuleDTO;
  projectId: string;
}) =>
  axiosClient.put<string, AxiosResponse<FastBoard.API.ApiModelRuleResponse>, FastBoard.API.CreateAndUpdateRuleDTO>(
    `/api/v2/model-rule/project/${projectId}/rule`,
    {
      modelId,
      name,
      id,
      isActive,
      query,
      variableNames,
      users,
      groups,
    },
  );

export const loadExportingUsersAndGroups = ({
  projectId,
  ruleId,
  typeFile,
}: LoadExportingUsersAndGroupsPayload): Promise<AxiosResponse<string>> =>
  axiosClient.get(`/api/v2/model-rule/project/${projectId}/rule/${ruleId}/export/${typeFile}`, { responseType: 'blob' });

export const importingUsersAndGroupsRule = ({ ruleId, projectId, file }: ImportingUsersAndGroupsRulePayload<FormData>) => {
  return axiosClient.post<string, AxiosResponse<FastBoard.API.ApiModelRuleResponse>, FormData>(
    `/api/v2/model-rule/project/${projectId}/rule/${ruleId}/import`,
    file,
  );
};
