import styled, { css } from 'styled-components';
import { defaultColorSize } from 'modules/settingsContainer/ColorPicker/constants';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Color } from 'modules/settingsContainer/ColorPicker/styles';

export interface ColorGridProps {
  color: string;
  active?: boolean;
  colorSize?: number;
}

export const ColorGrid = styled.div<ColorGridProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: ${({ colorSize }) => `${colorSize || defaultColorSize}px;`};
  width: ${({ colorSize }) => `${colorSize || defaultColorSize}px;`};
  background-color: ${({ color }) => color || 'unset'};
  cursor: pointer;
  transition: 0.2s;

  :hover {
    transform: scale(1.1);

    ${Color} {
      transform: none;
    }
  }

  ${({ active }) =>
    active &&
    css`
      border: 2px solid var(${ColorVarsEnum.Level_1});
      transform: scale(1.1);
    `}
`;
