import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getRolesListEnum } from 'store/reducers/auth/actions';
import { useAppDispatch } from 'store';
import { useRole } from 'utils/hooks/useRole';
import { goToFlowDraftURL } from 'app/providers/router/constants';

const AdminRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isAdmin } = useRole();

  useEffect(() => {
    dispatch(getRolesListEnum());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAdmin) {
    return <Navigate to={goToFlowDraftURL()} replace />;
  }

  return children;
};

export default AdminRoute;
