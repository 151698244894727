import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CheckingConnectionDataBaseData,
  SourceConnection,
  SourceConnectionKind,
  SourceDataInterface,
  SourcesActionsTypes,
  SourcesActiveLoading,
  UpdateConnection,
  UpdateSourceByIdPayload,
} from './types';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import {
  addSourceUsersGroupsAccess,
  createConnection,
  deleteScriptSource,
  deleteSource,
  deleteSourceAccessGroup,
  deleteSourceAccessUser,
  loadCheckingConnection,
  loadFilesPath,
  loadSourceById,
  loadSourceFileStatus,
  loadSources,
  loadSourcesActiveLoading,
  loadSourceUsersAndGroups,
  updateConnection,
  updateSourceFile,
  uploadSourceFile,
} from 'store/reducers/sources/api';
import {
  addConnection,
  changeActiveSourceId,
  deleteByIdScriptSource,
  deleteByIdSource,
  deleteByIdSourceAccess,
  setSlice,
  updateSourceAccess,
  updateSources,
} from '.';
import { TState } from 'store/index';
import { getSourcesList, getSourceUsersAndGroups } from 'store/reducers/sources/getters';
import { initialSourcesStoreState } from 'store/reducers/sources/constants';
import {
  AccessInterface,
  AddUsersGroupsSourceAccessPayload,
  UpdateGroupSourceAccessByIdPayload,
  UpdateUserSourceAccessByIdPayload,
} from 'types/types';
import { IdInterface } from 'types/store';
import { DeleteGroupSourceAccessByIdPayload, DeleteUserSourceAccessByIdPayload } from 'store/reducers/adminSources/types';
import { updateSourceAccessGroup, updateSourceAccessUser } from 'store/reducers/adminSources/api';
import { transformationFileForUpload } from 'utils/utils';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');

  return rejectWithValue(errorMessage);
};

export const loadSourcesAction = createAsyncThunk<SourceDataInterface[], string>(
  SourcesActionsTypes.LOAD_SOURCES,
  async (project_id: string, { rejectWithValue }) => {
    try {
      const response = await loadSources(project_id);
      return response.data;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as SourceDataInterface[];
    }
  },
);

export const loadFilesPathAction = createAsyncThunk<FastBoard.API.GetFoldersAndFilesResponse[], string>(
  SourcesActionsTypes.LOAD_FILES_PATH,
  async (path, { rejectWithValue }) => {
    try {
      const response = await loadFilesPath(path);

      return response.data;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as FastBoard.API.GetFoldersAndFilesResponse[];
    }
  },
);

export const loadCheckingConnectionAction = createAsyncThunk<string, CheckingConnectionDataBaseData>(
  SourcesActionsTypes.LOAD_FILES_PATH,
  async (source, { rejectWithValue }) => {
    try {
      const response = await loadCheckingConnection(source);

      return response.data.sourceTest;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const createTypedConnectionAction = <SourceConnectionType extends SourceConnectionKind = 'database'>() =>
  createAsyncThunk<
    FastBoard.API.SourcesCreateAndUpdateResponseDTO,
    SourceConnection<SourceConnectionType>,
    { rejectValue: null }
  >(SourcesActionsTypes.CREATE_CONNECTION, async (connectionData, { rejectWithValue }) => {
    try {
      const response = await createConnection(connectionData);
      Snackbar.show('Источник успешно создан.', 'success');

      return response.data.sourceCreated;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  });

export const updateConnectionTypeAction = <SourceConnectionType extends SourceConnectionKind>() =>
  createAsyncThunk<FastBoard.API.SourcesCreateAndUpdateResponseDTO, UpdateConnection<SourceConnectionType>>(
    SourcesActionsTypes.UPDATE_CONNECTION,
    async ({ id, sourceData }, { rejectWithValue }) => {
      try {
        const response = await updateConnection({ id, sourceData });
        Snackbar.show('Источник успешно обновлен.', 'success');

        return response.data.sourceUpdate;
      } catch (err: any) {
        return validateError(err, rejectWithValue);
      }
    },
  );

export const uploadSourceFileTypeAction = createAsyncThunk<string, File, { rejectValue: null }>(
  SourcesActionsTypes.UPLOAD_FILE,
  async (file, { rejectWithValue }) => {
    try {
      const uploadFile = transformationFileForUpload({ name: 'file', file });

      const response = await uploadSourceFile(uploadFile);

      Snackbar.show('Файл успешно загружен.', 'success');

      return response.data;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const loadSourceByIdTypeAction = <SourceConnectionType extends SourceConnectionKind>() =>
  createAsyncThunk<UpdateConnection<SourceConnectionType>, string>(
    SourcesActionsTypes.LOAD_SOURCE_BY_NAME,
    async (sourceId: string, { rejectWithValue }) => {
      try {
        const response = await loadSourceById(sourceId);

        const { id, name: nameData, credentials, driverId, autoUpdate } = response.data.source;

        return {
          id,
          sourceData: {
            name: nameData,
            driverId: driverId,
            credentials: credentials,
            autoUpdate,
          },
        } as UpdateConnection<SourceConnectionType>;
      } catch (err: any) {
        validateError(err, rejectWithValue);
        return rejectWithValue({});
      }
    },
  );

export const loadSourceFileStatusAction = createAsyncThunk<FastBoard.API.ProjectsLoadingStatusResponseDTO, string>(
  SourcesActionsTypes.LOAD_SOURCE_FILE_STATUS,
  async (loadingId: string, { rejectWithValue }) => {
    try {
      const response = await loadSourceFileStatus(loadingId);
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const updateSourceFileAction = createAsyncThunk<FastBoard.API.SourcesCreateAndUpdateResponseDTO, string>(
  SourcesActionsTypes.LOAD_SOURCE_FILE_TASK,
  async (sourceId: string, { rejectWithValue }) => {
    try {
      const response = await updateSourceFile(sourceId);
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

export const addSourceAction = createAsyncThunk<void, SourceDataInterface>(
  SourcesActionsTypes.UPLOAD_SOURCES,
  (data, { dispatch }) => {
    dispatch(addConnection(data));
  },
);

export const deleteByIdSourceAction = createAsyncThunk(
  SourcesActionsTypes.DELETE_BY_ID_SOURCE,
  (sourceId: string, { dispatch }) => {
    dispatch(deleteByIdSource(sourceId));
  },
);

export const changeActiveSourceIdAction = createAsyncThunk(
  SourcesActionsTypes.ACTIVE_SOURCE_ID,
  (sourceId: string, { dispatch }) => {
    dispatch(changeActiveSourceId(sourceId));
  },
);

export const deleteByIdScriptSourceAction = createAsyncThunk(
  SourcesActionsTypes.DELETE_BY_ID_SCRIPT_SOURCE,
  (sourceId: string, { dispatch, getState }) => {
    const sources = getSourcesList(getState() as TState).map((value) =>
      value.id === sourceId ? { ...value, selected: false } : value,
    );

    dispatch(deleteByIdScriptSource(sources));
  },
);

export const updateSourceByIdAction = createAsyncThunk<void, UpdateSourceByIdPayload>(
  SourcesActionsTypes.UPLOAD_SOURCES,
  ({ id, source }, { dispatch, getState }) => {
    const sources = getSourcesList(getState() as TState).map((value) => (value.id === id ? { ...value, ...source } : value));

    dispatch(updateSources(sources));
  },
);

export const deleteSourceByIdAction = createAsyncThunk<string, string, { rejectValue: null }>(
  SourcesActionsTypes.DELETE_SOURCE,
  async (sourceId, { rejectWithValue }) => {
    try {
      const response = await deleteSource(sourceId);
      Snackbar.show('Удалено', 'success');
      return response.data;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      validateError(err, rejectWithValue);
      return rejectWithValue(null);
    }
  },
);

export const deleteScriptSourceByIdAction = createAsyncThunk<
  string,
  { projectId: string; sourceId: string },
  { rejectValue: null }
>(SourcesActionsTypes.DELETE_SOURCE_SCRIPT, async ({ projectId, sourceId }, { rejectWithValue }) => {
  try {
    const response = await deleteScriptSource(projectId, sourceId);
    Snackbar.show('Удалено из скрипта', 'success');

    return response;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    validateError(err, rejectWithValue);
    return rejectWithValue(null);
  }
});

export const loadSourcesActiveLoadingAction = createAsyncThunk<SourcesActiveLoading[]>(
  SourcesActionsTypes.LOAD_SOURCES_ACTIVE_LOADING,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadSourcesActiveLoading();
      return response.data;
    } catch (err: any) {
      return validateError(err, rejectWithValue);
    }
  },
);

/* Access */

export const loadSourceUsersAndGroupsAction = createAsyncThunk<AccessInterface[], IdInterface, { rejectValue: null }>(
  SourcesActionsTypes.LOAD_SOURCES_USER_AND_GROUP,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await loadSourceUsersAndGroups(id);

      const { sourceUsersAndGroupsList } = response.data;

      return sourceUsersAndGroupsList.map(({ id, name, sourceGroupType, entity }) => ({
        entity,
        name,
        type: sourceGroupType,
        id,
      }));
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as AccessInterface[];
    }
  },
);

export const addUsersGroupsSourceAccessAction = createAsyncThunk<
  FastBoard.API.SourceAddUsersAndGroupsResponseDTO['kind'],
  AddUsersGroupsSourceAccessPayload
>(SourcesActionsTypes.ADD_SOURCE_USER_GROUP_ACCESS, async ({ sourceId, users, groups, type }, { rejectWithValue }) => {
  try {
    const response = await addSourceUsersGroupsAccess({ sourceId, users, groups, type });

    Snackbar.show('Доступы созданы', 'success');

    return response.data.kind;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    validateError(err, rejectWithValue);
    return rejectWithValue(null);
  }
});

export const deleteSourceAccessGroupAction = createAsyncThunk<
  FastBoard.API.FlowUserResponseDTO,
  DeleteGroupSourceAccessByIdPayload
>(SourcesActionsTypes.DELETE_GROUP_SOURCE_ACCESS_TYPE, async ({ groupId, sourceId }, { rejectWithValue }) => {
  try {
    const response = await deleteSourceAccessGroup({ sourceId, groupId });

    Snackbar.show('Доступ группы удален', 'success');

    return response.data;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const deleteSourceAccessUserAction = createAsyncThunk<
  FastBoard.API.FlowUserResponseDTO,
  DeleteUserSourceAccessByIdPayload
>(SourcesActionsTypes.DELETE_USER_SOURCE_ACCESS_TYPE, async ({ userId, sourceId }, { rejectWithValue }) => {
  try {
    const response = await deleteSourceAccessUser({ sourceId, userId });

    Snackbar.show('Доступ пользователя удален', 'success');

    return response.data;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const updateSourceAccessGroupAction = createAsyncThunk<
  FastBoard.API.ApiAdminSourceGroupeDTO,
  UpdateGroupSourceAccessByIdPayload
>(SourcesActionsTypes.UPDATE_SOURCE_ACCESS_CROUP, async ({ groupId, type, sourceId }, { rejectWithValue }) => {
  try {
    const response = await updateSourceAccessGroup({ sourceId, type, groupId });

    Snackbar.show('Статус группы изменен', 'success');

    return response.data.adminSourceGroup;
  } catch (err: any) {
    Snackbar.show('Ошибка', 'error');
    return validateError(err, rejectWithValue);
  }
});

export const updateSourceAccessUserAction = createAsyncThunk<FastBoard.API.SourceUserDTO, UpdateUserSourceAccessByIdPayload>(
  SourcesActionsTypes.UPDATE_SOURCE_ACCESS_USER,
  async ({ userId, type, sourceId }, { rejectWithValue }) => {
    try {
      const response = await updateSourceAccessUser({ sourceId, type, userId });

      Snackbar.show('Статус пользователя изменен', 'success');

      return response.data.adminSourceUser;
    } catch (err: any) {
      Snackbar.show('Ошибка', 'error');
      return validateError(err, rejectWithValue);
    }
  },
);

export const deleteByIdSourceAccessAction = createAsyncThunk(
  SourcesActionsTypes.DELETE_BY_ID_SOURCE_ACCESS,
  (id: string, { dispatch }) => {
    dispatch(deleteByIdSourceAccess({ id }));
  },
);

export const updateSourceAccessByIdAction = createAsyncThunk<void, { sourceAccess: AccessInterface }>(
  SourcesActionsTypes.UPDATE_SOURCE_ACCESS,
  async ({ sourceAccess }, { dispatch, getState }) => {
    const sourceAccesses = getSourceUsersAndGroups(getState() as TState).sourcesUsersAndGroupsList.map((value) =>
      value?.id === sourceAccess?.id ? { ...value, ...sourceAccess } : value,
    );

    dispatch(updateSourceAccess(sourceAccesses));
  },
);

export const clearSourcesStore = createAsyncThunk(SourcesActionsTypes.CLEAR_SOURCES, (_, { dispatch }) => {
  dispatch(setSlice(initialSourcesStoreState));
});

export const loadSourceByIdAction = loadSourceByIdTypeAction();
export const createConnectionAction = createTypedConnectionAction();
export const updateConnectionAction = updateConnectionTypeAction();
export const createDataBaseConnectionAction = createTypedConnectionAction<'database'>();
export const createFileConnectionAction = createTypedConnectionAction<'file'>();
export const updateDataBaseConnectionAction = updateConnectionTypeAction<'database'>();
export const updateFileConnectionAction = updateConnectionTypeAction<'file'>();
