import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { ExportPagesInterface, ImportPagesInterface } from './types';

export const loadPages = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectPagesResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/pages`);

export const loadFirstPageId = (projectId: string): Promise<AxiosResponse<FastBoard.API.ProjectPagesFirstPageIdResponseDTO>> =>
  axiosClient.get(`/api/v2/project/${projectId}/firstPageId`);

export const loadRLSUserAndGroup = (projectId: string): Promise<AxiosResponse<FastBoard.API.UserGroupListResponseDTO>> =>
  axiosClient.get(`/api/v2/model-rule/project/${projectId}/rule/users-and-groups/list`);

export const exportPages = ({
  projectId,
  data,
}: ExportPagesInterface): Promise<AxiosResponse<FastBoard.API.UserGroupListResponseDTO>> =>
  axiosClient.post(`/api/v2/project/pages/${projectId}/export`, data);

export const importPages = ({
  projectId,
  file,
  newPages,
  importModels,
}: ImportPagesInterface<FormData>): Promise<AxiosResponse<FastBoard.API.UserGroupListResponseDTO>> =>
  axiosClient.post(`/api/v2/project/pages/${projectId}/import`, file, {
    params: {
      importModels,
      newPages,
    },
  });
