import { AdminRoute, Route } from './types';

export const RoutesAdminURL: Record<AdminRoute, string> = {
  adminUsers: 'admin',
  adminUser: 'adminUser',
  adminFlows: 'adminFlows',
  adminLicenses: 'adminLicenses',
  adminGroups: 'adminGroups',
  adminTasks: 'adminTasks',
  adminActiveDirectories: 'adminActiveDirectories',
  adminSettings: 'adminSettings',
  adminSources: 'adminSources',
};

export const RoutesURL: Record<Route, string> = {
  preview: 'preview',
  login: 'login',
  projects: 'projects',
  projectsFlows: 'projects/flows',
  projectsFlowsId: 'projects/flows/:id',
  list: '/list',
  access: '/access',
  projectsFlowsDrafts: 'projects/flows/drafts',
  model: 'model',
  console: 'console',
  board: 'board',
};

export const getProjectIdUrl = (url: string) => `:projectId/${url}`;

export const goToFlowDraftURL = () => `/${RoutesURL.projectsFlowsDrafts}`;
export const goToFlowListURL = (flowId: string) => `/${RoutesURL.projectsFlows}/${flowId}${RoutesURL.list}`;
export const goToFlowAccessURL = (flowId: string) => `/${RoutesURL.projectsFlows}/${flowId}${RoutesURL.access}`;
export const goToProjectUrl = (url: string, projectId?: string) => `/${projectId || ''}/${url}`;
export const goToBoardUrl = (projectId?: string, pageId?: string) =>
  `${goToProjectUrl(RoutesURL.board, projectId)}/${pageId !== undefined ? pageId : '0'}`;
export const goToConsolePageUrl = (projectId?: string) => goToProjectUrl(RoutesURL.console, projectId);
export const goToModelPageUrl = (projectId?: string) => goToProjectUrl(RoutesURL.model, projectId);
