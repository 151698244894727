import { ProjectManagerSliceStateInterface, SortProject } from 'store/reducers/projectManager/types';

export const defaultShortProject: SortProject = {
  sortOrder: 'ascending',
  sortType: 'lastModified',
};

export const initialProjectManagerStoreState: ProjectManagerSliceStateInterface = {
  flows: {
    flowsList: [],
    loading: false,
  },
  flow: {
    flowProjectsList: [],
    loading: false,
  },
  draftFlow: {
    flowProjectsList: [],
    loading: false,
  },
  activeFlow: {
    id: '',
    title: 'Черновики',
  },
  activeProjectId: null,
  flowProjectInfo: null,
  flowProjectHistoryVersion: {
    flowProjectHistoryVersionList: [],
    loading: false,
  },
  flowDraftInfo: null,
  flowAccesses: {
    flowAccessList: [],
    loading: false,
  },
  usersGroupsList: {
    usersGroupsList: [],
    loading: false,
  },
  shortProject: defaultShortProject,
  meta: null,
};
