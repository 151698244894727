import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { authService } from 'services/AuthServices';
import { getToken, getUserLoading } from 'store/reducers/auth';
import { useAppDispatch } from 'store';
import { getHasAccessToProject, getUser } from 'store/reducers/auth/actions';
import { KeysLocalStorage } from 'constants/LocalStorage';
import Snackbar from 'services/Snackbar';
import { Loader } from 'shared/ui/Loader';
import { goToFlowDraftURL, RoutesURL } from 'app/providers/router/constants';
import { ProjectIdParam } from 'app/providers/router/types';

const PrivateRoute: ({ children }: { children: any }) => JSX.Element = ({ children }) => {
  const { token } = authService;
  const userToken = useSelector(getToken);
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams<ProjectIdParam>();
  const dispatch = useAppDispatch();
  const isAuthenticated = token && userToken;
  const userLoading = useSelector(getUserLoading);
  const ref = useRef(true);
  const firstRender = ref.current;

  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  useEffect(() => {
    const checkingProjectAccess = async () => {
      if (!projectId || !isAuthenticated) return;

      try {
        const responseAccess = await dispatch(getHasAccessToProject({ navigate, projectId }));

        if (!responseAccess.payload) {
          navigate(goToFlowDraftURL());
        }
      } catch (error) {
        Snackbar.show('Внутренняя ошибка, попробуйте перезагрузить страницу', 'error');
      }
    };

    checkingProjectAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isAuthenticated]);

  if (!isAuthenticated) {
    authService.signOut();

    localStorage.setItem(KeysLocalStorage.lastLocal, location.pathname);
    projectId && localStorage.setItem(KeysLocalStorage.lastProjectId, projectId);
    return <Navigate to={'/' + RoutesURL.login} replace />;
  }

  if (userLoading || firstRender) {
    ref.current = false;
    return <Loader size="small" title="Получение данных пользователя" />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
