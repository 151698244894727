import { createSelector } from 'reselect';
import { getState } from 'store/utils';

export const getProjectManagerStore = createSelector(getState, (state) => state.projectManager);

export const getFlows = createSelector(getProjectManagerStore, (state) => state.flows);

export const getMeta = createSelector(getProjectManagerStore, (state) => state.meta);

export const getFlowsAndDraft = createSelector(getProjectManagerStore, (state) => [
  state.flowDraftInfo,
  ...state.flows.flowsList,
]);

export const getActiveProjectId = createSelector(getProjectManagerStore, (state) => state.activeProjectId);

export const getFlowDraftInfoId = createSelector(getProjectManagerStore, (state) => state.flowDraftInfo?.id);

export const getFlowProjectHistoryVersion = createSelector(getProjectManagerStore, (state) => state.flowProjectHistoryVersion);

export const getFlowDraftInfo = createSelector(getProjectManagerStore, (state) => state.flowDraftInfo);

export const getActiveFlowId = createSelector(getProjectManagerStore, (state) => state.activeFlow);

export const getFlow = createSelector(getProjectManagerStore, (state) => state.flow);

export const getFlowDraft = createSelector(getProjectManagerStore, (state) => state.draftFlow);

export const getFlowAccesses = createSelector(getProjectManagerStore, (state) => state.flowAccesses);

export const getUsersGroups = createSelector(getProjectManagerStore, (state) => state.usersGroupsList);

export const getShortProject = createSelector(getProjectManagerStore, (state) => state.shortProject);
