import { GroupItemInterface, PaletteColorItemInterface } from '../store/reducers/palettes/types';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';

interface IsTheJSONvalidProps<TypeJson> {
  json: string;
  validateFunc: (json: TypeJson) => boolean;
}

export const isTheJSONvalid = <TypeJson>({ json, validateFunc }: IsTheJSONvalidProps<TypeJson>) => {
  try {
    const parseJson: TypeJson = JSON.parse(json);

    return validateFunc(parseJson);
  } catch (error) {
    return false;
  }
};

export const isValidColorsJSON = (color: PaletteColorItemInterface) => {
  return isString(color.id) && isNumber(color.h) && isNumber(color.s) && isNumber(color.l) && isString(color.hex);
};

export const isValidGroupsColorJSON = (groups: GroupItemInterface[]) =>
  isArray(groups) &&
  groups.reduce<boolean>(
    (_, group) => isString(group.id) && isString(group.name) && isArray(group.colors) && group.colors.every(isValidColorsJSON),
    false,
  );
